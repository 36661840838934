export const CONDITIONS_TYPE = (t: string): string => {
  if (!t) return t;
  switch (t.toLowerCase()) {
    case "warning":
      return "accent";
    default:
      return t;
  }
};

export const CONDITIONS_ICON = (t: string): string | null => {
  if (!t) return "close-circle";
  switch (t.toLowerCase()) {
    case "success":
      return "check-circle";
    case "warning":
      return "minus-circle";
    case "error":
      return "close-circle";
    default:
      return "close-circle";
  }
};

























import { Component, Prop, Vue } from "vue-property-decorator";
import { ListFilter } from "@/models/entities/list-filter.interface";
import { CONDITIONS_ICON, CONDITIONS_TYPE } from "@/helpers/conditions";

@Component
export default class CosmopolFilter extends Vue {
  @Prop({ required: true, default: null, type: Object })
  private filter: ListFilter;
  @Prop({ required: false, default: null, type: String })
  private isActive: string;

  get _isActive(): boolean {
    return this.isActive === this.filter.class;
  }

  get num(): string {
    const n = this.filter.rows as number;
    const length = this.filter.rows.toString().length;
    const ranges = [
      { l: 6, suffix: "k" },
      { l: 9, suffix: "M" },
      { l: 12, suffix: "B" },
    ];
    for (let i = 0; i < ranges.length; i++) {
      if (length > 3 && length <= ranges[i].l)
        return (
          (n / Math.pow(10, ranges[i].l - 3)).toFixed(1) + ranges[i].suffix
        );
    }
    return n.toString();
  }

  private getType(s: string): string {
    return CONDITIONS_TYPE(s);
  }

  private getIcon(s: string): string {
    return CONDITIONS_ICON(s);
  }

  private onClick(): void {
    this.$emit("on-click", this._isActive ? null : this.filter.class);
  }
}
